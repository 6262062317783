import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CvPage = () => (
  <Layout>
    <SEO title="CV" />
    <div className="cv-wrapper">
      <div className="wrapper">
        <div className="flexed">

          <div itemscope itemtype="https://data-vocabulary.org/Person">
            <address>
              <span itemprop="affiliation">
                <h3>
                  Software: <a href="https://sexier.software">Sexier Software Blog</a>
                </h3>
              </span>
            </address>
          </div>

        </div>
      </div>
    </div>
  </Layout>
)

export default CvPage
